import React, {
  ComponentProps,
  FormEvent,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react';

const BetterForm = ({onSubmit, ...rest}: ComponentProps<'form'>, ref: React.Ref<HTMLFormElement>): ReactElement => {
  const formRef = useRef<HTMLFormElement>(null);

  useImperativeHandle(ref, () => formRef.current!, []);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const form = event.currentTarget as HTMLFormElement;
      form.querySelectorAll('[data-required]').forEach(child => {
        child.setAttribute('required', 'true');
      });
      if (form.reportValidity()) {
        onSubmit?.(event);
      }
    },
    [onSubmit]
  );

  // Remove required attribute from all required fields, so they don't appear as :invalid on page load
  // Replace with data-required attribute, which is used in handleSubmit to restore required attribute
  useLayoutEffect(() => {
    formRef.current?.querySelectorAll('[required]').forEach(child => {
      child.removeAttribute('required');
      child.setAttribute('data-required', 'true');
    });
  }, []);

  return <form ref={formRef} {...rest} onSubmit={handleSubmit} />;
};

export default React.forwardRef<HTMLFormElement, ComponentProps<'form'>>(BetterForm);
