import {FirebaseError} from 'firebase/app';
import {signInWithEmailAndPassword, signInWithPopup} from 'firebase/auth';
import {InferGetStaticPropsType} from 'next';
import Image from 'next/legacy/image';
import Link from 'next/link';
import {useRouter} from 'next/router';
import React, {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import {notify} from 'src/features/common/util/bugsnag';

import signinImg from '../../public/img/signin.jpg';
import BetterForm from '../features/common/BetterForm';
import Col from '../features/common/Col';
import {useFirebaseAuth} from '../features/common/EnvironmentProvider';
import Row from '../features/common/Row';
import {googleAuthProvider} from '../features/firebase/public';
import {getCmsEntry} from '../features/strapi';
import {ApiSigninSignin} from '../features/strapi/generated/contentTypes';
import Button from '../features/tool/components/buttons/Button';
import {getToolLayout} from '../features/tool/components/Layout';
import toolIcons from '../features/tool/icons';
import {buildStaticPropsResponse} from '../features/tool/util/dataFetch';
import {getGoUrl} from '../redirect';
import URLS from '../urls';
import {NextPageWithLayout} from './_app';

type FormData = {
  email: string;
  password: string;
};

const UNREPORTED_ERRORS = ['auth/invalid-email', 'auth/user-not-found', 'auth/wrong-password'];

const SigninPage: NextPageWithLayout<InferGetStaticPropsType<typeof getStaticProps>> = ({signin}) => {
  const firebaseAuth = useFirebaseAuth();
  const {
    register,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm<FormData>();
  const router = useRouter();

  const [signingIn, setSigningIn] = useState(false);

  const onSubmit = useCallback(
    async ({email, password}: FormData) => {
      if (!firebaseAuth) throw new Error('Firebase App not initialized');
      try {
        setSigningIn(true);
        await signInWithEmailAndPassword(firebaseAuth, email, password);
        await router.push(getGoUrl(router.query) ?? URLS.dashboard);
      } catch (error) {
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case 'auth/invalid-email':
            case 'auth/user-not-found':
              setError('email', {type: 'custom', message: 'Email not found'});
              break;
            default:
              setError('password', {type: 'custom', message: 'Wrong password'});
              break;
          }
        } else setError('password', {type: 'custom', message: 'Wrong password'});
        setSigningIn(false);
        // Set the error visible on Bugsnag
        if (!(error instanceof FirebaseError && UNREPORTED_ERRORS.includes(error.code))) notify(error as Error);
      }
    },
    [firebaseAuth, router, setError]
  );

  const handleGoogleSigninClick = async () => {
    if (!firebaseAuth) throw new Error('Firebase App not initialized');
    try {
      await signInWithPopup(firebaseAuth, googleAuthProvider);
      await router.push(getGoUrl(router.query) ?? URLS.dashboard);
    } catch (error) {
      console.log(error);
      setError('password', {
        type: 'custom',
        message: "Couldn't sign in using Google, please try another authentication method.",
      });
      // Set the error visible on Bugsnag
      if (!(error instanceof FirebaseError && UNREPORTED_ERRORS.includes(error.code))) notify(error as Error);
    }
  };

  const GoogleIcon = React.cloneElement(toolIcons['google'], {className: 'w-xs h-xs'});

  return (
    <Row className="flex-1">
      <div className="flex flex-1 justify-center">
        <Col className="w-full px-lg mobile:px-lg py-[3.5rem] max-w-[62rem]">
          <h1 className="font-semibold text-3xl leading-none mb-sm">{signin.title}</h1>
          <p className="text-sm">{signin.subtitle}</p>
          <BetterForm className="mt-md space-y-sm" onSubmit={handleSubmit(onSubmit)}>
            <Col className="gap-2">
              <label htmlFor="email">{signin.email_field}</label>
              <input
                className={errors.email ? 'error' : undefined}
                id="email"
                required
                {...register('email')}
                type="email"
              />
              {errors.email && <p className="text-xs text-red-200">{errors.email.message}</p>}
            </Col>
            <Col className="gap-2">
              <label htmlFor="password">{signin.password_field}</label>
              <input
                className={errors.password ? 'error' : undefined}
                id="password"
                type="password"
                required
                {...register('password')}
              />
              {errors.password && <p className="text-xs text-red-200">{errors.password.message}</p>}
              <Link href={URLS.forgotPassword} className="text-sm text-right">
                Forgot your password?
              </Link>
            </Col>
            <Button className="w-full" type="submit" variant="primary" loading={signingIn}>
              {signin.submit_button}
            </Button>
          </BetterForm>
          <div className="relative flex py-xs items-center">
            <div className="flex-grow border-t-thin border-gray-100"></div>
            <span className="mx-xs text-sm">OR</span>
            <div className="flex-grow border-t-thin border-gray-100"></div>
          </div>
          <Button accessory={GoogleIcon} variant="secondary" align="center" onClick={handleGoogleSigninClick}>
            {signin.google_signin_button}
          </Button>
          <div className="text-sm text-center pt-xs space-x-xs">
            <span>Don&apos;t have an account?</span>
            <Link href={URLS.signup} className="font-medium text-primary-600 hover:text-primary-800">
              Sign up
            </Link>
          </div>
        </Col>
      </div>
      <Col className="relative flex-1 mobile:hidden">
        <Image alt="Signin image" src={signinImg} layout="fill" objectFit="cover" />
      </Col>
    </Row>
  );
};

export const getStaticProps = async () => {
  return buildStaticPropsResponse(await getCmsEntry<ApiSigninSignin, 'signin'>({path: 'signin'}));
};
export default SigninPage;

SigninPage.getLayout = getToolLayout;

SigninPage.title = 'Sign in';
